import React, { ReactNode } from "react";
import { Link as GatsbyLink } from "gatsby";
import { InlineText, Ol, Paragraph, TextContainer, Ul } from "../../lib/src";
import { DocPageMeta, HeaderSection, ContentSection, DocsTable, Code, Header } from "../docs";

export const Meta: DocPageMeta = {
	category: "Writing",
};

const M = ({ children }: { children: ReactNode }) => (
	<InlineText size="medium" emphasis="medium">
		{children}
	</InlineText>
);

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Grammar and mechanics"
			subTitle="Grammar, punctuation, capitalization, and spelling all play a role in how we present information to users. Consistency in these areas creates a more consistent user experience and helps position Siteimprove as a reliable and trustworthy source of knowledge."
		/>
		<ContentSection>
			<TextContainer article>
				<Header.H2>Abbreviations</Header.H2>
				<Header.H3>Latin abbreviations</Header.H3>
				<Paragraph>
					Lowercase with periods. Note: it’s better to use real words if you have the space.
				</Paragraph>
				<Ul
					items={[
						"etc. → and so on",
						"e.g. → for example",
						"i.e. → that is",
						"vs. → or, and, compared to",
					]}
				/>
				<Header.H3>Acronyms</Header.H3>
				<Paragraph>Uppercase with no periods.</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> SEO
						</>,
						<>
							<M>No:</M> S.E.O.
						</>,
						<>
							<M>No:</M> Seo
						</>,
					]}
				/>
				<Paragraph>
					Make sure you explain unfamiliar acronyms with a tooltip. For longer copy, spell out the
					acronym in the first instance.
				</Paragraph>
				<Paragraph>
					Avoid obscure acronyms (industry slang), and never attempt to coin your own.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> Single keyword ad group
						</>,
						<>
							<M>No:</M> SKAG
						</>,
					]}
				/>
				<Header.H3>Common abbreviations</Header.H3>
				<Paragraph>
					Common abbreviations are fine in microcopy (where space is limited), just don’t go
					overboard. Remember to put a period after the abbreviated word.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> Max. amount
						</>,
						<>
							<M>No:</M> Max amount
						</>,
						<>
							<M>No:</M> Mx. amt.
						</>,
					]}
				/>
				<Header.H3>Contractions</Header.H3>
				<Paragraph>Don't be afraid to use them! It helps us sound conversational.</Paragraph>
				<Paragraph>
					<M>Exception:</M> Use two separate words to emphasize issues and warnings.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> Images should not be larger than 1 MB
						</>,
						<>
							<M>No:</M> This page isn't included in the sitemap
						</>,
					]}
				/>
				<Header.H2>Active vs. passive voice</Header.H2>
				<Paragraph>
					Use the active voice as often as possible. This means focusing on the person doing the
					thing (the user), instead of the thing having something done to it. This helps users to
					understand whose responsibility it is to carry out a task.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> You need to update your preferences
						</>,
						<>
							<M>No:</M> Your preferences need to be updated
						</>,
					]}
				/>
				<Paragraph>
					<M>Exception:</M> Use the passive voice to avoid blaming or shaming users, especially when
					writing about issues.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> This thing was set up incorrectly
						</>,
						<>
							<M>No:</M> You set this thing up incorrectly
						</>,
					]}
				/>
				<Paragraph>
					<M>Exception:</M> Use the passive voice when the thing is more important (to the user)
					than the person doing the thing.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> Data is updated every Sunday
						</>,
						<>
							<M>No:</M> Siteimprove updates your data every Sunday
						</>,
					]}
				/>
				<Header.H2>Capitalization</Header.H2>
				<Header.H3>What we capitalize (title case)</Header.H3>
				<Ul
					items={[
						<>Proper nouns (people, places, brand names)</>,
						<>
							Siteimprove products and features (must be unique to the Siteimprove brand) such as:
							<Ul items={[<>Accessibility</>, <>DCI Score</>]} />
						</>,
					]}
				/>
				<Header.H3>What we don't capitalize (sentence case)</Header.H3>
				<Ul
					items={[
						<>
							Menu items (except for{" "}
							<a href="https://siteimprove-wgs.atlassian.net/wiki/spaces/UX/pages/4450943154/Product+and+feature+names+Sentence+case">
								products and features unique to Siteimprove
							</a>
							)
						</>,
						<>
							Concepts that aren't unique to the Siteimprove brand (like settings, notifications,
							issues, recommendations)
							<Ul items={[<>Edit settings</>, <>Turn off notifications</>, <>View issues</>]} />
						</>,
						<>
							Words like accessibility, performance, ads, when not referring to Siteimprove products
						</>,
						<>
							Page headings at any level, unless the heading includes the name of a product or
							feature unique to Siteimprove
						</>,
						<>
							Card titles, unless the heading includes the name of a product or feature unique to
							Siteimprove
						</>,
						<>Pronouns (your, my) — except where the pronoun comes first such as "My Sites"</>,
					]}
				/>
				<Header.H2>Compound words</Header.H2>
				<Paragraph>Compound words are created when you join two or more words together.</Paragraph>
				<Header.H3>When to hyphenate</Header.H3>
				<Ul
					items={[
						<>
							Compound adjectives that come <em>before</em> a noun. <M>Example:</M> This is a
							mobile-friendly site.
						</>,
						<>
							Nouns that are created from a verb and a preposition{" "}
							<Ul
								items={[
									<>
										<M>Yes:</M> View all add-ons
									</>,
									<>
										<M>Exceptions:</M> Login, plugin
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H3>When not to hyphenate</Header.H3>
				<Ul
					items={[
						<>
							Compound adjectives that come <em>after</em> a noun. <M>Example:</M> This site is
							mobile friendly.
						</>,
						<>
							Phrasal verbs (verb and a preposition)
							<Ul
								items={[
									<>
										<M>Yes:</M> Sign up for a free trial
									</>,
									<>
										<M>No:</M> Sign-up for a free trial
									</>,
								]}
							/>
						</>,
						<>
							After the "re" prefix, unless a hyphen would improve readability
							<Ul
								items={[
									<>
										<M>Yes:</M> Recheck page
									</>,
									<>
										<M>Exceptions:</M> Re-examine, re-enter, re-emphasize
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H2>Dates and times</Header.H2>
				<Header.H3>Dates</Header.H3>
				<Paragraph>
					Date formats differ between markets. Pay close attention to commas and word order.
				</Paragraph>
				<Paragraph>
					In <M>North America</M>, the month comes first.
				</Paragraph>
				<Ul items={["March 25, 2021", "Thursday, March 25, 2021", "3/25/21"]} />
				<Paragraph>
					In <M>Europe and Asia Pacific</M>, the day comes first.
				</Paragraph>
				<Ul items={["25 March 2021", "Thursday, 25 March 2021", "25/3/21"]} />
				<Paragraph>
					Note: we do <em>not</em> include superscripts.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>No:</M> March 25<sup>th</sup>
						</>,
						<>
							<M>Yes:</M> March 25
						</>,
					]}
				/>
				<Header.H3>Times</Header.H3>
				<Paragraph>
					Use 12-hour format for North American and Asia Pacific markets and 24-hour format for all
					other markets.
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Time</th>
							<th>12-hour format</th>
							<th>24-hour format</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Midnight</td>
							<td>12:00 AM</td>
							<td>00:00</td>
						</tr>
						<tr>
							<td>Three o’clock in the morning</td>
							<td>03:00 AM</td>
							<td>03:00</td>
						</tr>
						<tr>
							<td>Seven o’clock in the morning</td>
							<td>07:00 AM</td>
							<td>07:00</td>
						</tr>
						<tr>
							<td>Noon (midday) </td>
							<td>12:00 AM</td>
							<td>12:00</td>
						</tr>
						<tr>
							<td>Two thirty in the afternoon</td>
							<td>02:30 PM</td>
							<td>14:30</td>
						</tr>
						<tr>
							<td>Eight o’clock in the evening</td>
							<td>08:00 PM</td>
							<td>20:00</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H2>File extensions</Header.H2>
				<Paragraph>
					When making a general reference to a file extension type, use all uppercase without a
					period. Add a lowercase s for plurals.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> GIF
						</>,
						<>
							<M>Yes:</M> GIFs
						</>,
						<>
							<M>No:</M> .gif
						</>,
					]}
				/>
				<Header.H2>Lists</Header.H2>
				<Paragraph>
					Use a <GatsbyLink to="/lib/components/Text/List">list</GatsbyLink> to group related items
					or ideas in place of a longer paragraph. For more writing guidelines concerning lists,
					please see the component's{" "}
					<GatsbyLink to="/lib/components/Text/List#guidelines">guidelines</GatsbyLink> section.
				</Paragraph>
				<Header.H3>Ordered list</Header.H3>
				<Paragraph>
					Use an ordered list to communicate a specific priority, hierarchy, or sequence.{" "}
					<M>Example:</M>{" "}
				</Paragraph>
				<Ol items={["Enter a search term", "Hit Enter", "See your results"]} />
				<Header.H3>Unordered list</Header.H3>
				<Paragraph>
					Use an unordered list when the order of the items is not important. <M>Example:</M> When
					you visit Copenhagen, you can:
				</Paragraph>
				<Ul items={["go on a canal tour", "try smørrebrød", "visit the Siteimprove HQ"]} />
				<Header.H2>Names and titles</Header.H2>
				<Paragraph>
					Address users by their first name in everyday emails and messages. Avoid using their name
					too much in the platform—this can come across as creepy.
				</Paragraph>
				<Ul items={["Hi Eddie", "Thank you, Mads!"]} />
				<Header.H3>When to capitalize job titles (title case)</Header.H3>
				<Paragraph>When referring to a specific person or department:</Paragraph>
				<Ul
					items={[
						"Today’s webinar with Technical Writer Seán Needham",
						"Siteimprove’s award-winning Customer Success team",
					]}
				/>
				<Header.H3>When not to capitalize job titles (title case)</Header.H3>
				<Paragraph>When referring to a general field or area of responsibility:</Paragraph>
				<Ul
					items={["This is a great product for marketing teams", "Calling all product designers!"]}
				/>
				<Header.H2>Nominalization</Header.H2>
				<Paragraph>
					Nominalizations are abstract nouns created from verbs. A lot of words ending in “-tion” or
					“-ment” are nominalizations.
				</Paragraph>
				<Paragraph>
					Avoid using these types of words in the UI &mdash; especially when writing about what the
					user can or should do. Be bold and use the verb instead. This should make your message
					clearer.
				</Paragraph>
				<Ul
					items={[
						"Make optimizations → Optimize",
						"Take into consideration → Consider",
						"Add to approved words → Approve word",
					]}
				/>
				<Paragraph>
					<M>Exceptions:</M> Static product and feature names.
				</Paragraph>
				<Ul items={["Keyword Discovery", "Landing Page Experience"]} />
				<Paragraph>
					<M>Exception to the exceptions:</M> Never attempt to invent your own nominalizations to
					describe new features or functionalities. If you’re having trouble naming something, ask
					your UX writer!
				</Paragraph>
				<Header.H2>Numbers and units</Header.H2>
				<Header.H3>Currency</Header.H3>
				<Header.H4>Symbol or acronym</Header.H4>
				<Paragraph>
					In all currencies, place the symbol or acronym before the monetary amount.
				</Paragraph>
				<Ul items={["DKK 5,000", "$1.2 million", "€1"]} />
				<Header.H4>Decimal separators</Header.H4>
				<Paragraph>Most English-speaking countries use the decimal point:</Paragraph>
				<Ul items={["£1.75", "$16.50"]} />
				<Paragraph>Other markets (including most of Europe) may use a comma:</Paragraph>
				<Ul items={["£1,75", "$16,50"]} />
				<Header.H4>Thousand separators</Header.H4>
				<Paragraph>Most English-speaking countries use the comma to mark thousands:</Paragraph>
				<Ul items={["£525,000", "$63,789,004"]} />
				<Paragraph>Other markets may use a period or a space:</Paragraph>
				<Ul
					items={[
						"€200.000 (Two hundred thousand euros)",
						"€200 000 (Two hundred thousand euros)",
						"€7.000.000 (Seven million euros)",
						"€7 000 000 (Seven million euros)",
					]}
				/>
				<Header.H3>Fractions vs. percentages</Header.H3>
				<Paragraph>
					Use percentages (%) instead of fractions wherever possible. Fractions are harder to read
					and scan for, and they put extra cognitive load on the user.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> 75% of users
						</>,
						<>
							<M>No:</M> 3/4 user
						</>,
						<>
							<M>No:</M> Three-fourths of users
						</>,
					]}
				/>
				<Paragraph>
					<M>Exception:</M> In longer copy, it’s okay to spell out fractions to emphasize
					interesting statistics (“One in ten people…”).
				</Paragraph>
				<Paragraph>Always use the % symbol instead of spelling out "percent".</Paragraph>
				<Header.H3>Numerals</Header.H3>
				<Paragraph>
					Use numerals in microcopy and headings. For longer content, spell out numerals from one to
					ten unless you’re referring to customer data or percentages.
				</Paragraph>
				<Ul
					items={[
						"There are three things you can do on this page…",
						"…affects one in ten people…",
						"Less than 8% of…",
						"1 of 5 items selected",
						"See results for 5 sites",
					]}
				/>
				<Header.H3>Units of measurements</Header.H3>
				<Paragraph>
					For storage and memory, user uppercase letters with a space in between the number and the
					unit of measurement.
				</Paragraph>
				<Ul items={["1 MB", "500 KB"]} />
				<Paragraph>
					For dimensions, use lowercase letters with a space in between the number and the unit of
					measurement. There’s no need to include the unit of measurement twice.
				</Paragraph>
				<Ul items={["500 x 500 px", "60 x 60 cm"]} />
				<Header.H2>Personal pronouns</Header.H2>
				<Ul
					items={[
						<>
							Use “you” and “your” as if you’re speaking directly to the user &mdash; this makes us
							sound more personable.
							<Ul
								items={[
									<>
										<M>Yes:</M> On this page, you can…
									</>,
									<>
										<M>Maybe:</M> On this page, it’s possible to…
									</>,
									<>
										<M>No:</M> On this page, users can…
									</>,
									<>
										<M>No:</M> On this page, our customers can…
									</>,
								]}
							/>
						</>,
						<>
							Use “we” and “our” when talking about Siteimprove (but try to keep our ego in check).{" "}
							<Ul
								items={[
									<>
										<M>Yes:</M> Someone from our Technical Support team will contact you.
									</>,
									<>
										<M>No:</M> Someone from our hardworking and dedicated Technical Support team
										will contact you.
									</>,
									<>
										<M>Yes:</M> Let us know how we can improve this feature.
									</>,
									<>
										<M>No:</M> Our team has worked so hard to bring you this feature. Please let us
										know if you think it could be improved, and we’ll try our best to consider your
										request.
									</>,
								]}
							/>
						</>,
						<>
							Avoid using gendered pronouns (he, him, his, she, her, hers). Usually, it’s quite easy
							to drop the pronoun altogether.{" "}
							<Ul
								items={[
									<>
										<M>No:</M> Enter his or her full name
									</>,
									<>
										<M>Maybe:</M> Enter their full name
									</>,
									<>
										<M>Yes:</M> Enter the user’s full name
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H2>Place names</Header.H2>
				<Paragraph>
					Always keep our global audience in mind. Spell out city, state, and country names to avoid
					ambiguity.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> California
						</>,
						<>
							<M>Yes:</M> Canada
						</>,
						<>
							<M>No:</M> CA
						</>,
					]}
				/>
				<Paragraph>
					For countries with common abbreviations, you can spell out the name in the first instance
					and use the abbreviation in subsequent mentions.
				</Paragraph>
				<Ul items={[<>United States (US)</>, <>European Union (EU)</>, <>United Kingdom (UK)</>]} />
				<Header.H2>Plain language</Header.H2>
				<Paragraph>
					Our users come from different backgrounds and cultures. We want to be as accommodating as
					possible, regardless of their language skills and technical abilities.
				</Paragraph>
				<Ul
					items={[
						"Use simple, familiar words to describe things",
						"Only use technical terms if it’s really necessary",
						"Keep explanations concise, and sentences short",
					]}
				/>
				<Header.H2>Punctuation</Header.H2>
				<Paragraph>This section features guidelines for the following punctuation marks:</Paragraph>
				<Ul
					items={[
						<>
							<a href="#ampersand">Ampersand</a>
						</>,
						<>
							<a href="#ampersand">Apostrophe</a>
						</>,
						<>
							<a href="#colons">Colons</a>
						</>,
						<>
							<a href="#comma">Comma</a>
						</>,
						<>
							<a href="#dashes-and-hyphens">Dashes and hyphens</a>
						</>,
						<>
							<a href="#ellipses">Ellipses</a>
						</>,
						<>
							<a href="#exclamation-points">Exclamation points</a>
						</>,
						<>
							<a href="#question-marks">Question marks</a>
						</>,
						<>
							<a href="#quotation-marks">Quotation marks</a>
						</>,
						<>
							<a href="#semicolons">Semicolons</a>
						</>,
					]}
				/>
				<Header.H3>Ampersand</Header.H3>
				<Paragraph>
					Only use an ampersand if it belongs in a brand name. Otherwise, spell out “and”.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> Search and replace
						</>,
						<>
							<M>No:</M> Search &amp; replace
						</>,
					]}
				/>
				<Header.H3>Apostrophe</Header.H3>
				<Header.H4>When to use an apostrophe</Header.H4>
				<Ul
					items={[
						<>
							To show possession
							<Ul
								items={[
									<>Singular: Siteimprove's birthday</>,
									<>Words ending with s: James's PPC ads</>,
									<>Plural: visitors' names</>,
								]}
							/>
						</>,
						<>
							For common contractions
							<Ul
								items={[
									<>
										<M>Yes:</M> do not → don’t
									</>,
									<>
										<M>Yes:</M> cannot → can’t
									</>,
									<>
										<M>Yes:</M> it is → it’s
									</>,
									<>And so on</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H4>When not to use an apostrophe</Header.H4>
				<Ul
					items={[
						<>
							After acronyms
							<Ul
								items={[
									<>
										<M>Yes:</M> JPGs
									</>,
									<>
										<M>No:</M> JPG's
									</>,
								]}
							/>
						</>,
						<>For quotes (use double quotation marks instead)</>,
					]}
				/>
				<Header.H3>Colons</Header.H3>
				<Paragraph>Note: Use colons sparingly!</Paragraph>
				<Header.H4>When to use a colon</Header.H4>
				<Ul
					items={[
						<>
							To introduce a list. <M>Example:</M> Siteimprove Policy can help you manage all areas
							of your website: style guides, legal policies, brand guidelines, and more.
						</>,
						<>
							To emphasize a point. <M>Example:</M> The most important thing: proper colon use.
						</>,
					]}
				/>
				<Header.H4>When not to use a colon</Header.H4>
				<Ul
					items={[
						<>
							In the middle of a command or action
							<Ul
								items={[
									<>
										<M>No:</M> Go to: My Sites
									</>,
									<>
										<M>Yes:</M> Go to My Sites
									</>,
								]}
							/>
						</>,
						<>To separate labels from dropdowns or form fields</>,
						<>To introduce sections in longer copy &mdash; use a subheading instead</>,
						<>
							To highlight dynamic content &mdash; use bold formatting instead
							<Ul
								items={[
									<>
										<M>No:</M> Manage settings for: Cathy's campaign
									</>,
									<>
										<M>Yes:</M> Manage settings for{" "}
										<InlineText size="medium" emphasis="strong">
											Cathy's
										</InlineText>{" "}
										campaign
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H3>Comma</Header.H3>
				<Paragraph>
					We use the Oxford comma &mdash; a comma before the word “and” at the end of a list.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>Yes:</M> Siteimprove's core values are to be people-centric, passionate, and
							innovative.
						</>,
						<>
							<M>No:</M> Siteimprove's core values are to be people-centric, passionate and
							innovative.
						</>,
					]}
				/>
				<Header.H3>Dashes and hyphens</Header.H3>
				<Header.H4>Em dash (&mdash;)</Header.H4>
				<Paragraph>
					Em dashes help us sound conversational, and can make longer copy easier to scan. Use an em
					dash, by writing <Code>{"&mdash;"}</Code> in HTML, to:
				</Paragraph>
				<Ul
					items={["interrupt a thought", "break up a complex sentence", "emphasize a conclusion"]}
				/>
				<Paragraph>
					<InlineText size="medium" emphasis="strong">
						Example:
					</InlineText>{" "}
					We need you to tell us about the purpose, context, or meaning of different page elements
					&mdash; in other words, information that only a human being can provide.
				</Paragraph>
				<Header.H4>En dash (&ndash;)</Header.H4>
				<Paragraph>
					En dashes are used to show a range of dates or times. Create one by writing{" "}
					<Code>{"&ndash;"}</Code> in HTML.
				</Paragraph>
				<Paragraph>
					<InlineText size="medium" emphasis="strong">
						Example:
					</InlineText>{" "}
					Visit us July 4&ndash;8 between 7&ndash;10 a.m.
				</Paragraph>
				<Header.H4>Hyphen (-)</Header.H4>
				<Paragraph>Hyphens are used to:</Paragraph>
				<Ul
					items={[
						<>create compound words</>,
						<>
							improve the readability of prefixed words{" "}
							<Ul
								items={[
									<>
										<M>Yes:</M> Re-examine
									</>,
									<>
										<M>No:</M> Reexamine (sounds like a medication)
									</>,
								]}
							/>
						</>,
					]}
				/>
				<Header.H3>Ellipses</Header.H3>
				<Paragraph>Use sparingly, and never to create a sense of drama or suspense.</Paragraph>
				<Paragraph>
					Use them in a quote or code snippet to indicate you're omitting something. Offset by a
					space on either side.
				</Paragraph>
				<Paragraph>
					<M>Example:</M> "It's allowed us to make improvements we didn't even know existed, …
					saving us time and enabling us to govern our pages more strategically," he said.
				</Paragraph>
				<Header.H3>Exclamation points</Header.H3>
				<Paragraph>Use sparingly, and never in a headline or button!</Paragraph>
				<Header.H3>Periods</Header.H3>
				<Header.H4>When to use periods</Header.H4>
				<Ul
					items={[
						<>At the end of full sentences (descriptions, tooltips, Learn components)</>,
						<>In Latin abbreviations: e.g. etc.</>,
						<>
							See <a href="#decimal-separators">decimal separators</a>
						</>,
					]}
				/>
				<Header.H4>When not to use periods</Header.H4>
				<Ul items={["In headings and titles", "In microcopy (button text, labels, dropdowns)"]} />
				<Header.H3>Question marks</Header.H3>
				<Paragraph>Questions should be used sparingly in the UI.</Paragraph>
				<Paragraph>
					Avoid rhetorical questions, and never “ask” questions on behalf of the user &mdash; these
					are often more confusing than helpful.
				</Paragraph>
				<Ul
					items={[
						<>
							<M>No:</M> Why is SEO important?
						</>,
						<>
							<M>No:</M> What’s the worst that could happen?
						</>,
					]}
				/>
				<Paragraph>
					It’s fine to ask questions if we're asking for confirmation or user input. <M>Example:</M>{" "}
					Delete this Activity Plan?
				</Paragraph>
				<Paragraph>
					<M>Exception:</M> Help Centre article titles are often phrased as questions. This is
					acceptable because users are actively searching for answers (and not just trying to
					navigate the platform).
				</Paragraph>
				<Header.H3>Quotation marks</Header.H3>
				<Ul
					items={[
						<>
							Punctuation goes <em>inside</em> quotation marks of a direct quote. <M>Example:</M>{" "}
							"Siteimprove is the best!" said Morten.
						</>,
						<>
							Punctuation goes <em>outside</em> quotation marks when not part of a direct quote.{" "}
							<M>Example:</M> Have you heard of "digital certainty"?
						</>,
						<>
							Use singular quotation marks for quotes within quotes. <M>Example:</M> "We've been
							told to ‘keep up the good work’" said Torben.
						</>,
					]}
				/>
				<Header.H3>Semicolons</Header.H3>
				<Paragraph>
					Use sparingly. If you have the urge to use a semicolon, it might indicate that the
					sentence is too complex for the average reader.
				</Paragraph>
				<Header.H2>US vs. UK English</Header.H2>
				<Paragraph>Our source language is American English.</Paragraph>
				<Paragraph>
					If you’re unsure about spelling (“is it email or e-mail?”), you can check the{" "}
					<a href="https://www.apstylebook.com/">AP Stylebook</a>. Ask your UX writer for login
					details
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);
